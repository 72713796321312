<template>
  <b-overlay :show="loading">
    <file-upload-modal-component
      ref="uploadModal"
      :types="fileTypes"
      @file-selected="onFileSelected"
    />

    <div class="card">
      <div class="card-header">
        <h4 class="card-title">
          Información general
        </h4>
      </div>
      <div class="card-body">
        <div class="row">
          <div class="col-12 col-md-6">
            <div class="form-group">
              <label for="first_name">Nombre(s)</label>
              <input
                id="first_name"
                v-model="model.first_name"
                type="text"
                class="form-control"
                placeholder="Nombre(s)"
              >
            </div>
          </div>
          <div class="col-12 col-md-6">
            <div class="form-group">
              <label for="last_name">Apellido(s)</label>
              <input
                id="last_name"
                v-model="model.last_name"
                type="text"
                class="form-control"
                placeholder="Apellido(s)"
              >
            </div>
          </div>

          <div class="col-12 col-md-6">
            <div class="form-group">
              <label for="phone">Teléfono</label>
              <input
                id="phone"
                v-model="model.phone"
                type="tel"
                class="form-control"
                placeholder="Teléfono"
              >
            </div>
          </div>
          <div class="col-12 col-md-6">
            <div class="form-group">
              <label for="email">Correo electrónico</label>
              <input
                id="email"
                v-model="model.email"
                type="text"
                class="form-control"
                placeholder="Correo electrónico"
              >
            </div>
          </div>

          <div class="col-12 col-md-4">
            <div class="form-group">
              <label for="curp">CURP</label>
              <input
                id="curp"
                v-model="model.curp"
                type="text"
                class="form-control"
                placeholder="CURP"
              >
            </div>
          </div>
          <div class="col-12 col-md-4">
            <div class="form-group">
              <label for="rfc">RFC</label>
              <input
                id="rfc"
                v-model="model.rfc"
                type="text"
                class="form-control"
                placeholder="RFC"
              >
            </div>
          </div>
          <div class="col-12 col-md-4">
            <div class="form-group">
              <label for="elector_key">Clave de elector</label>
              <input
                id="elector_key"
                v-model="model.elector_key"
                type="text"
                class="form-control"
                placeholder="Clave de elector"
              >
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="card">
      <div class="card-header">
        <div class="card-title">
          <h4>Información de domicilio</h4>
        </div>
        <button
          class="btn btn-info"
          @click="addAddress"
        >
          <feather-icon icon="PlusIcon" />
          Agregar domicilio
        </button>
      </div>
      <div class="card-body">
        <div
          v-for="(address, i) in model.addresses"
          :key="i"
          class="row"
        >
          <div class="col-12">
            <h6 class="card-title">
              Dirección {{ i + 1 }}
            </h6>
            <button
              v-if="model.addresses.length>1"
              class="btn btn-danger"
              @click="removeAddress(i)"
            >
              <feather-icon icon="PlusIcon" />
              Borrar domicilio
            </button>
          </div>
          <div class="col-12 col-md-6">
            <div class="form-group">
              <label>Calle</label>
              <input
                v-model="address.street"
                type="text"
                class="form-control"
                placeholder="Calle"
              >
            </div>
          </div>
          <div class="col-12 col-md-6">
            <div class="form-group">
              <label>Teléfono</label>
              <input
                v-model="address.phone"
                type="tel"
                class="form-control"
                placeholder="Teléfono"
              >
            </div>
          </div>
          <div class="col-12 col-md-6">
            <div class="form-group">
              <label>Número exterior</label>
              <input
                v-model="address.ext_number"
                type="text"
                class="form-control"
                placeholder="Número exterior"
              >
            </div>
          </div>
          <div class="col-12 col-md-6">
            <div class="form-group">
              <label>Número interior</label>
              <input
                v-model="address.int_number"
                type="text"
                class="form-control"
                placeholder="Número interior"
              >
            </div>
          </div>
          <div class="col-12 col-md-4">
            <div class="form-group">
              <label>Estado</label>
              <v-select
                v-model="address.state"
                label="text"
                :options="states"
                :clearable="false"

                :get-option-label="option=>option.getAttr('name')"
              />
            </div>
          </div>
          <div class="col-12 col-md-4">
            <div class="form-group">
              <label>Ciudad</label>
              <v-select
                v-model="address.city_id"
                label="text"
                :options="address.state ? address.state.getAttr('cities') : []"
                :clearable="false"
                :reduce="value=>value.id"
                :get-option-label="option=>option.name"
              />
            </div>
          </div>
          <div class="col-12 col-md-4">
            <div class="form-group">
              <label>Colonia</label>
              <input
                v-model="address.colony"
                type="text"
                class="form-control"
                placeholder="Colonia"
              >
            </div>
          </div>

          <hr class="w-100">
        </div>
      </div>
    </div>

    <div class="card">
      <div class="card-header">
        <div class="card-title">
          <h4>Archivos adjuntos</h4>
        </div>
        <button
          class="btn btn-info"
          @click="$refs.uploadModal.show()"
        >
          <feather-icon icon="PlusIcon" />
          Agregar archivo
        </button>
      </div>
      <div class="card-body">
        <div class="row">
          <div class="col-12 d-flex flex-wrap">
            <div
              v-for="(file, i) in model.files"
              :key="i"
              class="card mr-2"
              style="width:200px;"
            >
              <img
                class="card-img-top"
                :src="file.file_url"
                :alt="getFileLabel(file)"
              >
              <div class="card-body">
                <p class="text-center">
                  {{ getFileLabel(file) }}
                </p>
              </div>
            </div>
          </div>

          <div
            v-if="model.files.length===0"
            class="col-12"
          >
            <h6 class="text-center">
              No se han agregado archivos
            </h6>
          </div>
        </div>
      </div>
    </div>

    <div class="row">
      <div class="col-12">
        <div class="btn-group float-right">
          <button
            class="btn btn-danger"
            @click="$router.go(-1)"
          >
            Cancelar
          </button>
          <button
            class="btn btn-primary"
            @click="save"
          >
            Guardar
          </button>
        </div>

      </div>
    </div>
  </b-overlay>
</template>

<script>
import vSelect from 'vue-select'
import State from '@/dc-it/models/State'
import { ref, onMounted } from '@vue/composition-api'
import FileUploadModalComponent from '@/views/components/modals/FileUploadModalComponent.vue'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import GeneralService from '@/dc-it/services/GeneralService'
import { BOverlay } from 'bootstrap-vue'
import Customer from '@/dc-it/models/Customer'

export default {
  name: 'CustomerFormView',
  components: { FileUploadModalComponent, vSelect, BOverlay },
  data() {
    return {
      cities: [],
      fileTypes: [
        {
          name: 'Identificación oficial (frente)',
          code: 'front_id',
          formats: ['.jpg', '.jpeg', '.png'],
        },
        {
          name: 'Identificación oficial (dorsal)',
          code: 'back_id',
          formats: ['.jpg', '.jpeg', '.png'],
        },
      ],
      emptyAddress: {
        street: '',
        ext_number: '',
        int_number: '',
        state: null,
        city_id: null,
        colony: '',
      },
    }
  },
  methods: {
    addAddress() {
      this.model.addresses.push({ ...this.emptyAddress })
    },
    removeAddress(index) {
      this.model.addresses.splice(index, 1)
      this.$forceUpdate()
    },
    onFileSelected(file) {
      this.model.files.push(file)
      this.$refs.uploadModal.hide()
    },
    getFileLabel(file) {
      const selectedType = this.fileTypes.find(type => type.code === file.type)
      return selectedType.name
    },
    save() {
      if (this.model.first_name === '') {
        this.showError('El nombre es requerido')
        return
      }
      if (this.model.last_name === '') {
        this.showError('El apellido es requerido')
        return
      }
      if (this.model.phone === '') {
        this.showError('El teléfono es requerido')
        return
      }
      if (this.model.email === '') {
        this.showError('El correo electrónico es requerido')
        return
      }
      if (this.model.curp === '') {
        this.showError('La CURP es requerida')
        return
      }
      if (this.model.rfc === '') {
        this.showError('El RFC es requerido')
        return
      }
      if (this.model.elector_key === '') {
        this.showError('La clave de elector es requerida')
        return
      }
      if (this.model.addresses.length === 0) {
        this.showError('Se requiere al menos una dirección')
        return
      }
      if (this.model.files.length === 0) {
        this.showError('Se requiere al menos un archivo')
        return
      }
      this.loading = true
      GeneralService.getInstance().save('customers', this.model).then(response => {
        if (response.result === 'success') {
          this.$router.back()
        } else {
          this.showError(response.error)
        }
        this.loading = false
      })
    },
    showError(err) {
      this.$toast({
        component: ToastificationContent,
        props: {
          title: 'Error',
          icon: 'XIcon',
          text: err,
          variant: 'warning',
        },
      })
    },
  },
  setup(props, context) {
    const states = ref([])
    const loading = ref(true)
    const model = ref({
      id: null,
      first_name: '',
      last_name: '',
      phone: '',
      email: '',
      curp: '',
      rfc: '',
      elector_key: '',
      addresses: [
        {
          street: '',
          ext_number: '',
          int_number: '',
          state: null,
          city_id: null,
          colony: '',
        },
      ],
      files: [],
    })
    const route = context.root.$route

    const getStates = async () => {
      states.value = await State.with(['cities']).get()
      if (route.params.id) {
        const customers = await Customer.where('id', '=', route.params.id).with(['addresses.city', 'mediable']).get()

        model.value = customers[0].toJSON()
        model.value.addresses = customers[0].getAttr('addresses').map(address => {
          const result = { ...address }
          result.state = states.value.find(state => state.getAttr('id').toString() === address.city.state_id.toString())
          // result.city_i

          return result
        })
        model.value.files = customers[0].getAttr('mediable')
      }

      loading.value = false
    }

    onMounted(() => {
      getStates()
    })

    return {
      states,
      loading,
      model,
    }
  },
}
</script>

<style scoped>

</style>
