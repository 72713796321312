<template>
  <b-modal
    ref="modal"
    :title="title"
    size="lg"
  >
    <div class="row">
      <div class="col-12">
        <div class="form-group">
          <label>Seleccione el tipo de archivo</label>
          <v-select
            v-model="type"
            label="text"
            :options="types"
            :clearable="false"

            :get-option-label="option=>option.name"
          />
        </div>
      </div>
      <div class="col-12">
        <div
          class="w-100 d-flex align-items-center justify-content-center"
          style="height: 250px; border: 1px dashed black; cursor: pointer"
          @click="addFile"
        >

          <div class="text-center">
            <feather-icon
              icon="FilePlusIcon"
              size="25"
            />
            <p>Seleccionar un archivo</p>
          </div>

          <input
            ref="input-element"
            type="file"
            style="display: none;"
            @change="inputChange"
          >

        </div>
      </div>
    </div>
  </b-modal>
</template>

<script>

import { BModal } from 'bootstrap-vue'
import vSelect from 'vue-select'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'

export default {
  name: 'FileUploadModalComponent',
  components: {
    BModal, vSelect,
  },
  props: {
    title: {
      type: String,
      default: 'Seleccionar archivo',
    },
    types: {
      type: Array,
      required: true,
    },
  },
  data() {
    return {
      type: null,
      multimedia: null,
    }
  },
  methods: {
    show() {
      this.$refs.modal.show()
    },
    hide() {
      this.$refs.modal.hide()
    },
    addFile() {
      if (!this.type || !this.type.code) {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: 'Error',
            icon: 'XIcon',
            text: 'Seleccione un tipo de archivo',
            variant: 'warning',
          },
        })
        return
      }
      const input = this.$refs['input-element']
      input.click()
    },
    inputChange() {
      const input = this.$refs['input-element']
      if (!(input.files && input.files)) {
        return
      }
      // eslint-disable-next-line no-restricted-syntax
      for (const file of input.files) {
        const reader = new FileReader()
        const { name } = file

        reader.onload = el => {
          // eslint-disable-next-line new-cap
          this.multimedia = {
            filename: name,
            file_url: el.target.result,
            type: this.type.code,
          }

          this.$emit('file-selected', this.multimedia)
        }

        // check formats with type.formats
        if (this.type.formats && this.type.formats.length > 0) {
          let valid = false
          // eslint-disable-next-line no-restricted-syntax
          for (const format of this.type.formats) {
            if (file.name.endsWith(format)) {
              valid = true
              break
            }
          }
          if (!valid) {
            this.$toast({
              component: ToastificationContent,
              props: {
                title: 'Error',
                icon: 'XIcon',
                text: `El archivo ${file.name} no se pudo agregar, ya que, no es un formato válido`,
                variant: 'warning',
              },
            })
            return
          }

          reader.readAsDataURL(file)
        }
      }
    },
  },
}
</script>

<style scoped>
</style>
