import Model from '@/dc-it/models/Model'
import Field from '@/dc-it/types/Field'

export default class State extends Model {
    endpoint = 'states';

    fields = [
      new Field('id', 'Código').disabled(),
      new Field('name', 'Nombre'),
    ]

    clone = () => State;
}
